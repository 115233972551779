.item-box {
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 1.7rem;

    .item-box__title {
        font-size: 1.5rem;
        line-height: 2.5rem;
        text-transform: uppercase;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: $orange;
    }

    .item-box__desc {
        margin-bottom: 3rem;
        color: $text-gray;
    }

    .item-box__article {
        p {
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }
    }

    .item-box__date {
        color: $text-gray;
        font-size: 1.4rem;
    }

    .item-box-document {
        margin-top: 3rem;

        .item-box-document__title {
            text-transform: uppercase;
            font-size: 2.5rem;
            border-bottom: 2px solid $text;
            padding-left: 0.5rem;
            margin-bottom: 1rem;
        }

        .item-box-document__box {
            display: flex;
            flex-direction: column;
        }

        .item-box-document__link {
            position: relative;
            display: block;
            margin: 0.5rem 0;
            padding-left: 25px;
            color: $orange;

            &:hover {
                text-decoration: underline;
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                display: inline-block;
                width: 18px;
                height: 24px;
                background-repeat: no-repeat;
                background-size: cover;
                background-image: url('/static/icons/pdfIcon.svg');
            }
        }
    }
}
