.home-company-info {
    padding-top: 1rem;

    @include breakpoint($lt-md) {
        padding-top: 0;
    }

    h2 {
        text-transform: uppercase;
        font-size: 3rem;
        text-align: center;
        margin-bottom: 5rem;

        @include breakpoint($lt-md) {
            margin-bottom: 3rem;
        }
    }

    .home-company-info__box {
        background-color: #ffffffbf;
    }

    .home-company-info__img-box {
        img {
            width: 100%;
        }
    }

    .home-company-info__text {
        padding: 7rem 7rem 0 7rem;

        @include breakpoint($lt-xl) {
            padding: 5rem 5rem 0 5rem;
        }

        @include breakpoint($lt-lg) {
            padding: 3rem 3rem 0 3rem;

            .home-company-info__title {
                margin-bottom: 2rem;
                font-size: 1.5rem;
                line-height: 2.2rem;
            }

            .home-company-info__desc {
                font-size: 1.3rem;
            }
        }

        @include breakpoint($lt-md) {
            padding: 3rem;
        }
    }

    .home-company-info__title {
        text-transform: uppercase;
        color: $orange;
        font-size: 1.6rem;
        line-height: 2.7rem;
        margin-bottom: 3rem;
    }

    .home-company-info__desc {
        font-size: 1.6rem;
    }
}

.home-advantages {
    background-color: $white;
    padding: 2rem 0;

    .home-advantages__item {
        padding: 3rem 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint($lt-md) {
            padding: 2rem 3rem;
        }
    }

    .home-advantages__icon {
        width: 100px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 3rem;

        @include breakpoint($lt-md) {
            width: 70px;
            height: 70px;
            margin-bottom: 1rem;
        }
    }

    .home-advantages__text {
        text-align: center;
        font-size: 2rem;

        @include breakpoint($lt-md) {
            font-size: 1.6rem;
        }
    }
}

.home-contact-info {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;

    .home-contact-info__title {
        text-transform: uppercase;
        margin-bottom: 1rem;

        @include breakpoint($lt-md) {
            font-size: 2.5rem;
        }
    }

    .home-contact-info__company-name {
        color: $orange;
        font-size: 2rem;

        @include breakpoint($lt-md) {
            font-size: 1.8rem;
        }
    }

    .home-contact-info__address,
    .home-contact-info__phone {
        font-size: 1.8rem;

        @include breakpoint($lt-md) {
            font-size: 1.4rem;
        }

        span {
            color: $orange;
        }
    }
}
