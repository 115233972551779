.orders {
    .orders__sidebar {
        padding-right: 1.5rem;

        @include breakpoint($lt-md) {
            padding-right: 0;
            margin-bottom: 1.5rem;
        }
    }

    .orders__box {
        background-color: $white;
        padding: 1.3rem;

        + .orders__box {
            margin-top: 1.5rem;
        }
    }

    .orders__box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
        text-transform: uppercase;
        color: $text-gray;
    }
}

.order-btn-box {
    .order-btn-box__item {
        border-bottom: 1px solid $border;
        padding: 1rem 0;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    .order-btn-box__btn {
        color: $orange;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.order-item {
    margin-bottom: 1.5rem;

    .order-item__box {
        padding: 1rem;
        border: 1px solid $border;
    }

    .order-item__meta {
        span {
            padding: 0 0.5rem;

            &:first-child {
                padding-left: 0;
            }

            + span {
                border-left: 1px solid $text-gray;
            }
        }
    }

    .order-item__title {
        font-size: 2.5rem;
        //cursor: pointer;

        //&:hover {
        //  text-decoration: underline;
        //}
    }

    .order-item__footer {
        display: flex;
        justify-content: space-between;
        color: $text-gray;
        border-top: 1px solid $border;

        padding-top: 0.75rem;
        margin-top: 0.5rem;
    }

    .order-item__review-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .order-item__review-icon {
            width: 36px;
            height: 36px;
            background-repeat: no-repeat;
            background-size: cover;

            &--ok {
                background-image: url('/static/icons/review-ok.svg');
            }
            &--normal {
                background-image: url('/static/icons/review-normal.svg');
            }
            &--bad {
                background-image: url('/static/icons/review-bad.svg');
            }
        }

        .btn {
            border-radius: 10px;
            font-size: 1.3rem;
        }
    }
}

.order-review-modal__btn-box {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    input[type='radio'] {
        display: none;

        &:checked {
            + .reviewLabel-bad {
                background-color: #ff3b30;
            }

            + .reviewLabel-normal {
                background-color: #ffa700;
            }

            + .reviewLabel-ok {
                background-color: #21b73a;
            }
        }
    }

    label {
        display: block;
        width: 96px;
        height: 46px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 0.5rem;
        border-radius: 4px;
        cursor: pointer;
    }

    .reviewLabel-bad {
        background-image: url('/static/icons/review-bad.svg');
        border: 1px solid #ff3b30;

        &:hover {
            background-color: #ff3b30;
        }
    }

    .reviewLabel-normal {
        background-image: url('/static/icons/review-normal.svg');
        border: 1px solid #ffa700;

        &:hover {
            background-color: #ffa700;
        }
    }

    .reviewLabel-ok {
        background-image: url('/static/icons/review-ok.svg');
        border: 1px solid #21b73a;

        &:hover {
            background-color: #21b73a;
        }
    }
}

.order-review-modal__textarea {
    min-height: 50px !important;
}

.order-item__desc {
    color: $text-gray;
    cursor: pointer;
    padding-right: 1.6rem;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 13px;
        height: 8px;
        background-image: url('/static/icons/arrow_toggle.svg');
        top: 5px;
        right: 0;
        transform: rotate(180deg);
    }

    &--short {
        &:after {
            transform: rotate(0deg);
        }
    }

    &--no-arrow {
        cursor: default;

        &:after {
            content: none;
        }
    }
}
