.news-carousel {
    padding-top: 5rem;
    padding-bottom: 5rem;

    &.white {
        background-color: $white;
    }

    .news-carousel__title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 4rem;
    }

    .news-carousel__item {
        padding: 0 3rem;
        display: flex !important;
        justify-content: center;

        @include breakpoint($lt-md) {
            padding: 0 4rem;
        }
    }

    .news-carousel__item-title {
        display: block;
        color: $orange;
        font-size: 1.8rem;
        text-transform: uppercase;
        margin-bottom: 1rem;

        &:hover {
            text-decoration: underline;
        }

        @include breakpoint($md) {
            font-size: 1.6rem;
            margin-bottom: 0.5rem;
        }
    }

    .news-carousel__item-desc {
        font-size: 1.6rem;
        margin-bottom: 0.5rem;

        @include breakpoint($md) {
            font-size: 1.4rem;
            margin-bottom: 0.2rem;
        }
    }

    .news-carousel__date {
        color: $text-gray;
        font-size: 1.2rem;
    }

    .slick-next,
    .slick-prev {
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        height: 70px;
        width: 16px;

        &:before {
            content: '';
        }

        @include breakpoint($lt-md) {
            height: 35px;
            width: 8px;
        }
    }

    .slick-next {
        background-image: url('/static/icons/arrowGrayRight.svg');

        @include breakpoint($lt-md) {
            right: 2%;
        }
    }

    .slick-prev {
        background-image: url('/static/icons/arrowGrayLeft.svg');

        @include breakpoint($lt-md) {
            left: 2%;
        }
    }

    .news-carousel__link-box {
        display: flex;
        justify-content: center;
        margin-top: 4rem;

        .news-carousel__link {
            color: $orange;
            text-transform: uppercase;
            border: 2px solid $orange;
            text-align: center;
            padding: 1rem 1.5rem;

            &:hover {
                color: $white;
                background-color: $orange;
            }
        }
    }
}
