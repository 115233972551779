.auth-btn {
    width: 150px;
    opacity: 0.8;
    background-color: $orange;
    text-align: center;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    transition: opacity 0.4s;
    color: $white;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    padding: 0.8rem 0.3rem 0.7rem 0.3rem;
    margin-left: 1rem;

    @include breakpoint($lt-xl) {
        width: 12vw;
        font-size: 1rem;
    }

    @include breakpoint($lt-lg) {
        width: 8vw;
        font-size: 0.8rem;
        margin-left: 0.5px;
    }

    @include breakpoint($lt-md) {
        width: 165px;
        font-size: 1.3rem;
        margin-top: 1rem;
        margin-left: 0;
    }

    &:hover {
        opacity: 1;
        color: $white;
    }
}

.auth-modal {
    .auth-modal__body {
        padding: 0 8rem;

        @include breakpoint($lt-xl) {
            padding: 0 6rem;
        }

        @include breakpoint($lt-lg) {
            padding: 0;
        }
    }

    .resending-sms__btn {
        color: $orange;

        &:hover {
            text-decoration: underline;
        }
    }
}
