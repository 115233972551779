$xs-var: 0px !default;
$sm-var: 576px !default;
$md-var: 768px !default;
$lg-var: 992px !default;
$xl-var: 1200px !default;

/** Меньше чем **/
$lt-xs: (
    max: 0px,
);
$lt-sm: (
    max: #{$sm-var - 1},
);
$lt-md: (
    max: #{$md-var - 1},
);
$lt-lg: (
    max: #{$lg-var - 1},
);
$lt-xl: (
    max: #{$xl-var - 1},
);

/** Больше чем **/
$gt-xs: (
    min: #{$sm-var},
);
$gt-sm: (
    min: #{$md-var},
);
$gt-md: (
    min: #{$lg-var},
);
$gt-lg: (
    min: #{$xl-var},
);
$gt-xl: (
    min: 9999px,
);

/** Фиксированный брейкпоинт **/
$xs: (
    min: 0,
    max: #{$sm-var - 1},
);
$sm: (
    min: #{$sm-var},
    max: #{$md-var - 1},
);
$md: (
    min: #{$md-var},
    max: #{$lg-var - 1},
);
$lg: (
    min: #{$lg-var},
    max: #{$xl-var - 1},
);
$xl: (
    min: #{$xl-var},
    max: 9999px,
);

@mixin breakpoint($map, $pixel-ratio: null) {
    $query: null;

    // Retina
    @if $pixel-ratio {
        @if type-of($pixel-ratio) == 'number' {
            $query: '(min-resolution: #{$pixel-ratio}dppx) and';
        } @else {
            @error "Invalid type for pixel ratio. #{$pixel-ratio} is not supported";
        }
    }

    @if map-has-key($map, min) {
        $query: '#{$query} (min-width: #{map-get($map, min)})';
    }
    @if map-has-key($map, min) and map-has-key($map, max) {
        $query: '#{$query} and';
    }
    @if map-has-key($map, max) {
        $query: '#{$query} (max-width: #{map-get($map, max)})';
    }

    @if $query {
        @media screen and #{$query} {
            @content;
        }
    } @else {
        @error "Invalid type for map. #{map} is not supported";
    }
}

body:before {
    opacity: 0;
    position: absolute;
    z-index: -9999;

    @include breakpoint($xs) {
        content: 'xs';
    }

    @include breakpoint($sm) {
        content: 'sm';
    }

    @include breakpoint($md) {
        content: 'md';
    }

    @include breakpoint($lg) {
        content: 'lg';
    }

    @include breakpoint($xl) {
        content: 'xl';
    }
}
