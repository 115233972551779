.footer {
    background-color: $footer;
    padding: 4rem 0;
    color: #ffffff;
    font-size: 1.6rem;
    margin-top: 5rem;

    &--not-margin {
        margin-top: 0;
    }

    @include breakpoint($lt-md) {
        font-size: 1.3rem;
    }
}
