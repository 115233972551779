.history-chart {
    .history-chart__box {
        background-color: $white;
        padding-top: 3rem;
        padding-bottom: 3em;
    }
}

.history-picker {
    padding: 1rem 2rem;
    display: flex;
    justify-content: flex-end;

    @include breakpoint($lt-md) {
        justify-content: center;
    }

    .react-datepicker-year-header,
    .react-datepicker {
        font-size: 1.2rem;
    }
}

.history-picker__btn {
    padding: 0.5rem 2rem;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    display: block;
    width: 100%;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    span {
        display: block;
        font-size: 1.2rem;
        line-height: 1.4rem;
    }
}

.react-datepicker-popper,
.react-datepicker-wrapper {
    + .react-datepicker-wrapper {
        margin-left: 1rem;
    }
}
