.payment-btn {
    width: 165px;
    opacity: 0.8;
    background-color: $orange;
    text-align: center;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    transition: opacity 0.4s;
    color: $white;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    padding: 0.8rem 0.3rem 0.7rem 0.3rem;

    @include breakpoint($lt-xl) {
        width: 12vw;
        font-size: 1rem;
    }

    @include breakpoint($lt-lg) {
        width: 12vw;
        font-size: 0.8rem;
    }

    @include breakpoint($lt-md) {
        width: 165px;
        font-size: 1.3rem;
        margin-top: 1rem;
    }

    &:hover {
        opacity: 1;
        color: $white;
    }
}

.payment-btn__modal {
    .payment-btn__modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .payment-btn__modal-icon {
        width: 38px;
        height: 43px;
        background-image: url('/static/icons/mobile.svg');
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 1rem;
    }

    .payment-btn__modal-text {
        text-align: center;
        margin-bottom: 1rem;
    }
}
