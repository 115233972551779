.history-list {
    .history-list__box {
        background-color: $white;
    }
}

.history-list__empty {
    padding: 10rem 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint($lt-md) {
        padding: 10rem 1rem;
    }

    .history-list__empty-icon {
        width: 85px;
        height: 65px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('/static/icons/no-counter-data.svg');
        margin-bottom: 1rem;
    }

    .history-list__empty-text {
        color: $text-gray-little;
        font-size: 2rem;
        text-align: center;
    }
}

.history-item {
    border-bottom: 1px solid $border;

    &:last-child {
        border-bottom: none;
    }
}

.history-item__box {
    padding: 2rem 4rem;

    @include breakpoint($lt-md) {
        padding: 2rem;
    }
}

.history-item__date-box {
    display: flex;
    align-items: center;
}

.history-item__date {
    font-size: 2rem;
    font-weight: bold;

    @include breakpoint($lt-md) {
        font-size: 2.4rem;
        margin-bottom: 1rem;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.history-item__counter {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }

    .history-item__counter-value {
        font-weight: bold;
    }

    .history-item__counter-name {
        padding-left: 29px;
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: calc(50% - 13px);
            width: 21px;
            height: 21px;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    &.cold-water {
        .history-item__counter-name:before {
            background-image: url('/static//icons/ColdWhater.svg');
        }
    }

    &.hot-water {
        .history-item__counter-name:before {
            background-image: url('/static//icons/HotWhater.svg');
        }
    }

    &.electric {
        .history-item__counter-name:before {
            background-image: url('/static//icons/ElectricPower.svg');
        }
    }

    &.heating {
        .history-item__counter-name:before {
            background-image: url('/static//icons/Heating.svg');
        }
    }
}
