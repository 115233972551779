$text: #212529 !default;
$text-gray: #6d6e72 !default;
$text-gray-little: #c7c7cc !default;
$orange: #f15f21 !default;
$red: #dc3545 !default;
$orange-hover: #e35d21 !default;
$white: #ffffff !default;
$bg-color: #e9eaeb !default;
$bg-color-hover: #c4c5c6 !default;
$modal-bg-color: #d8d8d8 !default;
$footer: #6d6d6d !default;
$border: #e9e9e9 !default;
