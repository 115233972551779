.file-loader {
    .file-list {
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
    }

    .file-list__item {
        display: flex;
        align-items: center;
        margin-right: 1rem;
    }

    .file-list__item-delete {
        opacity: 0.8;
        background-image: url('/static/icons/file-delete.svg');
        width: 12px;
        height: 12px;
        display: block;
        margin-left: 0.5rem;

        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }
}
