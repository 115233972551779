.cookies-pop-up {
    position: fixed;
    z-index: 1;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    gap: 1rem;
    padding: 1rem;
    background-color: #d8d8d8;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    @include breakpoint($lt-sm) {
        grid-template-columns: minmax(0, 1fr);
    }
    &__btn {
        display: block;
        width: 10rem;
        padding: 0.8rem 1rem 0.7rem;
        margin-left: auto;
        opacity: 0.8;
        background-color: #f15f21;
        text-align: center;
        transition: opacity 0.4s;
        color: #ffffff;
        font-size: 1.3rem;
        letter-spacing: 0.5px;
        @include breakpoint($lt-sm) {
            width: 100%;
        }
        &:hover {
            opacity: 1;
            color: #ffffff;
        }
    }
}