.manual-counter-select {
    .manual-counter-select__title {
        color: $text-gray;
        text-transform: uppercase;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
}

.manual-counter-select-item {
    margin-bottom: 1rem;

    .manual-counter-select-item__box {
        cursor: pointer;
        border: 1px solid $text-gray;
        padding: 1rem;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
            background-color: $bg-color-hover;
        }
    }

    .manual-counter-select-item__box-icon {
        width: 28px;
        height: 28px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 1rem;
    }

    .manual-counter-select-item__box-title {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    .manual-counter-select-item__box-current-value {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }

    &.hot-water {
        .manual-counter-select-item__box-icon {
            background-image: url('/static/icons/hot_water_color.svg');
        }
    }

    &.cold-water {
        .manual-counter-select-item__box-icon {
            background-image: url('/static/icons/cold_water_color.svg');
        }
    }

    &.in-form {
        .manual-counter-select-item__box {
            border: none;
            padding: 1rem 0;

            &:hover {
                cursor: default;
                background-color: transparent;
            }
        }
    }
}

.manual-counter-form {
    .is-invalid {
        background-image: none;
        border-color: #ced4da;

        &:focus {
            border-color: #ced4da;
            box-shadow: none;
        }
    }
}

.btn-manual-counter {
    @include breakpoint($lt-md) {
        width: 100%;
    }
}
