.home-carousel {
    .slick-next,
    .slick-prev {
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        height: 98px;
        width: 40px;

        &:before {
            content: '';
        }

        @include breakpoint($lt-md) {
            height: 49px;
            width: 20px;
        }
    }

    .slick-next {
        right: 18%;
        background-image: url('/static/icons/arrowRight.svg');

        @include breakpoint($lt-lg) {
            right: 10%;
        }

        @include breakpoint($lt-md) {
            right: 5%;
        }
    }

    .slick-prev {
        left: 18%;
        background-image: url('/static/icons/arrowLeft.svg');

        @include breakpoint($lt-lg) {
            left: 10%;
        }

        @include breakpoint($lt-md) {
            left: 5%;
        }
    }

    .slick-dots {
        bottom: 30px;
        z-index: 1;

        @include breakpoint($lt-md) {
            bottom: 10px;
        }

        li {
            width: 14px;
            height: 14px;

            @include breakpoint($lt-md) {
                width: 7px;
                height: 7px;
            }

            button {
                border-radius: 50%;
                background-color: $white;
                width: 14px;
                height: 14px;

                @include breakpoint($lt-md) {
                    width: 7px;
                    height: 7px;
                }

                &:before {
                    content: '';
                }
            }

            &.slick-active {
                button {
                    background-color: $orange;
                }
            }
        }
    }

    .home-carousel__img,
    .home-carousel__item {
        height: 34vw;

        @include breakpoint($lt-md) {
            height: 50vw;
        }
    }

    .home-carousel__img {
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home-carousel__item-title {
        font-family: 'Circe-Thin';
        font-size: 5.5rem;
        font-weight: 600;
        line-height: 6.3rem;
        color: $white;
        text-transform: uppercase;
        text-shadow: 0 0 10px rgba(6, 17, 29, 0.4);
        max-width: 50%;
        text-align: center;

        @include breakpoint($lt-xl) {
            font-size: 4.5rem;
            line-height: 5rem;
        }

        @include breakpoint($lt-md) {
            max-width: 80%;
            font-size: 2.2rem;
            line-height: 3rem;
        }
    }
}
