.mobile-app-links {
    display: flex;
    justify-content: center;

    .mobile-app-links__box {
        display: flex;
        flex-direction: column;
        align-items: center;

        + .mobile-app-links__box {
            margin-left: 1.5rem;
        }
    }

    .mobile-app-links__qr {
        margin-bottom: 2rem;
    }

    .mobile-app-links__play {
        //margin-right: 1rem;
    }

    img {
        max-width: 92px;
    }
}
